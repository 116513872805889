import React from "react"

const ContactusPage = () => {
  return (
    <>
      <section className="section py-5 bg-white">
        <div className="container container_bigger">
          <div className="row justify-content-md-center justify-content-xl-between">
            <div className="col-lg-12">
              <ul className="list-unstyled">
                <li>
                  <dl className="list-terms-medium list-terms-medium_secondary">
                    <dt>Our Address</dt>
                    <dd>
                      #6, A.M.Jain College Staff Colony, Nanganallur, Chennai,
                      India - 600061
                    </dd>
                  </dl>
                </li>
              </ul>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.069350944399!2d80.1845784!3d12.9707423!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8424f22ecfbc7f2d!2sMothersoft%20Technologies!5e0!3m2!1sen!2sin!4v1585295113145!5m2!1sen!2sin"
                width="100%"
                height="450"
                frameborder="0"
                style={{ border: "0" }}
                allowfullscreen=""
                aria-hidden="false"
                title="google map"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactusPage
