import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactusPage from "../components/contactus/index"
const contactus = () => {
  return (
    <Layout>
      <Seo
        title="Linux and Windows Reseller Hosting - Motherepos"
        desc="Motherepos Provides both Linux and windows reseller hosting with many features"
        pathname="/reseller/"
        keywords="linux reseller hosting, windows reseller hosting, reseller hosting"
      />
      <ContactusPage />
    </Layout>
  )
}

export default contactus
